import PropTypes from "prop-types";
import React from "react";

import LanguageMenu from "./language-menu.jsx";
import ThemeMenu from "./theme-menu.jsx";
import { MenuSection } from "../menu/menu.jsx";

const SettingsMenu = ({
    canChangeLanguage,
    canChangeTheme,
    isRtl,
    onRequestClose,
    onRequestOpen,
    settingsMenuOpen,
}) => (
            <MenuSection>
                {canChangeLanguage && (
                    <LanguageMenu onRequestCloseSettings={() => console.log("onRequestCloseSettings")}/> 
                )}
                {canChangeTheme && (
                    <ThemeMenu onRequestCloseSettings={() => console.log("onRequestCloseSettings")} />
                )}
            </MenuSection>
);

SettingsMenu.propTypes = {
    canChangeLanguage: PropTypes.bool,
    canChangeTheme: PropTypes.bool,
    isRtl: PropTypes.bool,
    onRequestClose: PropTypes.func,
    onRequestOpen: PropTypes.func,
    settingsMenuOpen: PropTypes.bool,
};

export default SettingsMenu;
